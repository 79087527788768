import SCREENS from '@src/SCREENS';

// This file is used to define RHP screens that are in relation to the search screen.
const SEARCH_TO_RHP: string[] = [
    SCREENS.SEARCH.REPORT_RHP,
    SCREENS.SEARCH.TRANSACTION_HOLD_REASON_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_CURRENCY_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_DATE_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_SUBMITTED_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_APPROVED_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_PAID_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_EXPORTED_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_POSTED_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_DESCRIPTION_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_MERCHANT_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_REPORT_ID_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_AMOUNT_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_CATEGORY_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_KEYWORD_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_TAX_RATE_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_EXPENSE_TYPE_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_TAG_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_FROM_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_TO_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_IN_RHP,
    SCREENS.SEARCH.ADVANCED_FILTERS_CARD_RHP,
    SCREENS.SEARCH.SAVED_SEARCH_RENAME_RHP,
];

export default SEARCH_TO_RHP;
