import Onyx from 'react-native-onyx';
import CONFIG from '@src/CONFIG';
import CONST from '@src/CONST';
import ONYXKEYS from '@src/ONYXKEYS';
import type Response from '@src/types/onyx/Response';
import {isConnectedAsDelegate, restoreDelegateSession} from './actions/Delegate';
import updateSessionAuthTokens from './actions/Session/updateSessionAuthTokens';
import redirectToSignIn from './actions/SignInRedirect';
import {getAuthenticateErrorMessage} from './ErrorUtils';
import Log from './Log';
import {post} from './Network';
import {getCredentials, setAuthToken, setIsAuthenticating} from './Network/NetworkStore';
import requireParameters from './requireParameters';

type Parameters = {
    useExpensifyLogin?: boolean;
    partnerName: string;
    partnerPassword: string;
    partnerUserID?: string;
    partnerUserSecret?: string;
    twoFactorAuthCode?: string;
    email?: string;
    authToken?: string;
};

let isAuthenticatingWithShortLivedToken = false;
Onyx.connect({
    key: ONYXKEYS.SESSION,
    callback: (value) => {
        isAuthenticatingWithShortLivedToken = !!value?.isAuthenticatingWithShortLivedToken;
    },
});

function Authenticate(parameters: Parameters): Promise<Response> {
    const commandName = 'Authenticate';

    requireParameters(['partnerName', 'partnerPassword', 'partnerUserID', 'partnerUserSecret'], parameters, commandName);

    return post(commandName, {
        // When authenticating for the first time, we pass useExpensifyLogin as true so we check
        // for credentials for the expensify partnerID to let users Authenticate with their expensify user
        // and password.
        useExpensifyLogin: parameters.useExpensifyLogin,
        partnerName: parameters.partnerName,
        partnerPassword: parameters.partnerPassword,
        partnerUserID: parameters.partnerUserID,
        partnerUserSecret: parameters.partnerUserSecret,
        twoFactorAuthCode: parameters.twoFactorAuthCode,
        authToken: parameters.authToken,
        shouldRetry: false,

        // Force this request to be made because the network queue is paused when re-authentication is happening
        forceNetworkRequest: true,

        // Add email param so the first Authenticate request is logged on the server w/ this email
        email: parameters.email,
    });
}

/**
 * Reauthenticate using the stored credentials and redirect to the sign in page if unable to do so.
 * @param [command] command name for logging purposes
 */
function reauthenticate(command = ''): Promise<void> | undefined {
    // Prevent re-authentication if authentication with shortLiveToken is in progress
    if (isAuthenticatingWithShortLivedToken) {
        return;
    }

    // Prevent any more requests from being processed while authentication happens
    setIsAuthenticating(true);

    const credentials = getCredentials();
    return Authenticate({
        useExpensifyLogin: false,
        partnerName: CONFIG.EXPENSIFY.PARTNER_NAME,
        partnerPassword: CONFIG.EXPENSIFY.PARTNER_PASSWORD,
        partnerUserID: credentials?.autoGeneratedLogin,
        partnerUserSecret: credentials?.autoGeneratedPassword,
    }).then((response) => {
        if (response.jsonCode === CONST.JSON_CODE.UNABLE_TO_RETRY) {
            // When a fetch() fails due to a network issue and an error is thrown we won't log the user out. Most likely they
            // have a spotty connection and will need to retry reauthenticate when they come back online. Error so it can be handled by the retry mechanism.
            throw new Error('Unable to retry Authenticate request');
        }

        // If authentication fails and we are online then log the user out
        if (response.jsonCode !== 200) {
            const errorMessage = getAuthenticateErrorMessage(response);
            setIsAuthenticating(false);
            Log.hmmm('Redirecting to Sign In because we failed to reauthenticate', {
                command,
                error: errorMessage,
            });
            redirectToSignIn(errorMessage);
            return;
        }

        // If we reauthenticated due to an expired delegate token, restore the delegate's original account.
        // This is because the credentials used to reauthenticate were for the delegate's original account, and not for the account they were connected as.
        if (isConnectedAsDelegate()) {
            Log.info('Reauthenticated while connected as a delegate. Restoring original account.');
            restoreDelegateSession(response);
            return;
        }

        // Update authToken in Onyx and in our local variables so that API requests will use the new authToken
        updateSessionAuthTokens(response.authToken, response.encryptedAuthToken);

        // Note: It is important to manually set the authToken that is in the store here since any requests that are hooked into
        // reauthenticate .then() will immediate post and use the local authToken. Onyx updates subscribers lately so it is not
        // enough to do the updateSessionAuthTokens() call above.
        setAuthToken(response.authToken ?? null);

        // The authentication process is finished so the network can be unpaused to continue processing requests
        setIsAuthenticating(false);
    });
}

export {reauthenticate, Authenticate};
